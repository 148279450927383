import axios from 'axios';

const API_URL = '/posts';

const fetchPosts = (page,limit,hostname) => axios.get(API_URL, { params: { page, limit,hostname } });

const createPost = (newPost) => axios.post(API_URL, newPost);
const updatePost = (id, updatedPost) => axios.put(`${API_URL}/${id}`, updatedPost);
const deletePost = (id) => axios.delete(`${API_URL}/${id}`);
const viewPost = (slug) => axios.get(`${API_URL}/${slug}`);


export default {
    fetchPosts,
    createPost,
    updatePost,
    deletePost,
    viewPost
};
