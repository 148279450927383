import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { CssBaseline, Box, Container } from '@mui/material';
import CreatePost from './components/CreatePost';
import Posts from "./components/Posts";
import Post from "./components/Post";
import Footer from "./components/Footer";
import Header from './components/Header'; // Import the Header component
import { Helmet } from 'react-helmet'; // Import Helmet
import { createTheme, ThemeProvider } from '@mui/material/styles';

// Create a theme instance.
const theme = createTheme({
    palette: {
        background: {
            default: '#f4f6f8' // Replace with your desired color
        },
    },
    components: {
        MuiCard: {
            defaultProps: {
                elevation: 0, // Set the default elevation to 0 for all Cards
            },
        },
    },
});

function App() {
    const hostname = window.location.hostname;

    return (
        <ThemeProvider theme={theme}> {/* Wrap with ThemeProvider */}
            <Router>
                <CssBaseline />
                {hostname === 'smartfinancetoday.net' && (
                    <Helmet>
                        <script>
                            {`
                                window._izq = window._izq || [];
                                window._izq.push(["init" ]);
                            `}
                        </script>
                        <script src="https://cdn.izooto.com/scripts/8cf018328c809bfa891ee02399a22f1b1bb2cada.js"></script>
                    </Helmet>
                )}
                <Header /> {/* Include the Header component here */}
                <Container maxWidth={"xl"}>
                    <Box display="flex" flexDirection="column" minHeight="100vh">
                        <Box component="main" flex="1 0 auto" py={2}>
                            <Routes>
                                <Route path="/create" element={<CreatePost />} />
                                <Route path="/" element={<Posts />} />
                                <Route path="/post/:slug" element={<Post />} />
                                {/* Add a route for the Health section */}
                                {/* Define other routes here */}
                            </Routes>
                        </Box>
                    </Box>
                </Container>
                <Box component="footer" flexShrink={0}>
                    <Footer />
                </Box>
            </Router>
        </ThemeProvider>
    );
}

export default App;