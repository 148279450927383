import React from 'react';
import {AppBar, Toolbar, Typography, Button, Container} from '@mui/material';
import {Link} from 'react-router-dom';

const Header = () => {
    return (
        <AppBar position="static" sx={{backgroundColor: 'black', color: 'white'}}
                elevation={0}> {/* Remove shadow by setting elevation to 0 */}
            <Container maxWidth={"xl"}>
                <Toolbar>
                    <Typography variant="h5" component="div" sx={{
                        marginRight: 0,
                        ml: 0,
                        pl: 0,
                        color: 'white',
                        fontWeight: 'bold'
                    }}> {/* Adjusted margin for spacing and make text color black */}
                        <Link to={'/'} style={{
                            textDecoration: 'none',
                            color: 'inherit'
                        }}>{window.location.hostname}</Link> {/* Ensure Link uses the parent's text color */}
                    </Typography>
                    {/* Directly after the site name, without flexGrow */}
                    {/*<Button color="inherit" component={Link} to="/health">*/}
                    {/*    Health*/}
                    {/*</Button>*/}
                    {/* Additional menu items can follow here */}
                </Toolbar>
            </Container>
        </AppBar>
    );
};

export default Header;
