import {Box, Link, Typography} from "@mui/material";

const Footer = () => {
    return (
        <Box sx={{
            position: 'relative',
            py: 2,
            width: '100%',
            textAlign: 'center',
            background: 'black',
            color: 'lightgray',
        }}>
        <Box sx={{ py: 4, textAlign: 'center', color: 'lightgray' }}>
            <Link href="https://typ.com/privacy" variant="subtitle1" sx={{color: "lightgray", textDecoration: "none"}}>
                Privacy Policy
            </Link>
            {' | '}
            <Link href="https://typ.com/terms-and-conditions" variant="subtitle1" sx={{color: "lightgray", textDecoration: "none"}}>
                Terms
            </Link>

        </Box>
        <Box>
                <Typography variant="body2" color="textSecondary" align="center" sx={{color: "lightgray"}}>
                    Disclaimer: The information on this site is not intended or implied to be a substitute for
                    professional financial and/or medical advice. All content, including text, graphics,
                    images and information, contained on or available through this web site is for general
                    information purposes only. The information and materials contained in these pages and the
                    terms, conditions and descriptions that appear, are subject to change without notice.
                </Typography>

                <Typography variant="body2" color="textSecondary" align="center" sx={{ mt: 4 }} sx={{color: "lightgray"}}>
                    © 2024 TYP NETWORK LTD PTD All Rights Reserved. | supply at typ.com
                </Typography>
        </Box>

    </Box>
    );
};

export default Footer