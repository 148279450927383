import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {
    Card,
    Grid,
    Typography,
    CardMedia,
    CardContent,
    Box,
    Pagination,
    CircularProgress,
    Divider
} from '@mui/material';
import PostsService from '../api/PostsService';
import {AccessTime, Timer} from "@mui/icons-material";
import NewPosts from "./newPosts";
import {Helmet} from "react-helmet";

function Posts() {
    const [posts, setPosts] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPosts, setTotalPosts] = useState(0);
    const limit = 20; // Puedes configurar este número como desees
    const [isLoading, setIsLoading] = useState(true); // Add this line


    useEffect(() => {
        setIsLoading(true); // Start loading
        PostsService.fetchPosts(page, limit, window.location.hostname)
            .then(response => {
                setPosts(response.data.data);
                setTotalPosts(response.data.total);
                setIsLoading(false); // End loading
            })
            .catch(error => {
                console.error("Error al obtener los posts", error);
                setIsLoading(false); // End loading even on error
            });
    }, [page]);

    const handlePageChange = (event, value) => {
        console.log('Page change', value);
        setPage(value);
    };

    const calculateReadingTime = (body, wpm = 200) => {
        // Parse the body JSON string into an object
        const content = JSON.parse(body);

        // Extract the text from the paragraphs
        let text = "";
        content.forEach(section => {
            section.paragraphs.forEach(paragraph => {
                text += paragraph.text + " ";
            });
        });

        // Count the number of words
        const wordCount = text.split(/\s+/).filter(word => word.length > 0).length;

        // Calculate the reading time in minutes
        const readingTime = wordCount / wpm;

        return readingTime.toFixed(0);
    }


    const styles = {
        lineClamp: {
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            WebkitLineClamp: 2,
            lineClamp: 2,
        },
    };

    const totalPages = Math.ceil(totalPosts / limit);

    return (
        <>
            <Helmet>
                <title>{window.location.hostname} - HOME</title>
                <meta name="description" content={`All you need to know about ${window.location.hostname}`}/>
            </Helmet>
            {isLoading ? ( // Check if content is loading
                <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                    <CircularProgress/>
                </Box>
            ) : (<>
                <Grid container spacing={3}>
                    {/* Asumiendo que el primer post debe ser más grande y abarcar dos filas */}
                    {posts.length > 0 && (
                        <Grid item xs={12} md={8} lg={8}>
                            <Card sx={{display: 'flex', flexDirection: 'column', height: 500}}>
                                <CardMedia
                                    component="img"
                                    image={posts[0].image}
                                    alt={posts[0].title}
                                    sx={{height: 370, width: '100%'}}
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div" style={styles.lineClamp}>
                                        <Link to={`/post/${posts[0].slug}`}
                                              style={{
                                                  textDecoration: 'none',
                                                  color: "black",
                                                  fontWeight: "bold"
                                              }}>{posts[0].title}</Link>
                                    </Typography>

                                    <Typography gutterBottom variant="body2" component="div" style={styles.lineClamp}>
                                        {posts[0].summary}
                                    </Typography>


                                </CardContent>
                            </Card>
                        </Grid>
                    )}
                    <Grid item xs={12} md={4} lg={4} container spacing={3}>
                        {posts.slice(1, 3).map(post => (
                            <Grid item xs={12} key={post._id}>
                                <Card raised sx={{display: 'flex', height: '100%'}}>
                                    <CardMedia
                                        component="img"
                                        sx={{width: 160, objectFit: 'cover'}}
                                        image={post.image}
                                        alt={post.title}
                                    />
                                    <Box sx={{display: 'flex', flexDirection: 'column', flexGrow: 1}}>
                                        <CardContent>
                                            <Typography gutterBottom variant="h6" component="div"
                                                        style={styles.lineClamp}>
                                                <Link to={`/post/${post.slug}`} style={{
                                                    textDecoration: 'none',
                                                    color: "black",
                                                    fontWeight: "bold"
                                                }}>{post.title}</Link>
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary" style={styles.lineClamp}>
                                                {post.summary}
                                            </Typography>
                                        </CardContent>
                                        <Box sx={{mt: 'auto', p: 2, display: 'flex', alignItems: 'center'}}>
                                            <AccessTime fontSize="small"/>
                                            <Typography variant="caption" sx={{ml: 0.5}}>
                                                {calculateReadingTime(post.body)} minute read
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
                <Typography variant={"h4"} sx={{py: 3, fontWeight: "bold"}}>JUST IN</Typography>
                <Divider orientation="horizontal" variant="middle" sx={{mb: 3, ml: 0, pl: 0, width: '100%'}}/>

                <NewPosts posts={posts.slice(3, 21)}/>
            </>)}

        </>
    );
}

export default Posts;
