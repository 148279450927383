import React from 'react';
import { Link } from "react-router-dom";
import { Card, Grid, Typography, CardMedia, CardContent, Box } from '@mui/material';
import { AccessTime } from "@mui/icons-material";

const newPosts = ({ posts }) => {
    if (!posts || posts.length === 0) {
        return <Typography variant="h6">No posts available</Typography>;
    }

    const styles = {
        lineClamp: {
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            WebkitLineClamp: 2,
            lineClamp: 2,
        },
    };

    const calculateReadingTime = (body, wpm = 200) => {
        // Parse the body JSON string into an object
        const content = JSON.parse(body);

        // Extract the text from the paragraphs
        let text = "";
        content.forEach(section => {
            section.paragraphs.forEach(paragraph => {
                text += paragraph.text + " ";
            });
        });

        // Count the number of words
        const wordCount = text.split(/\s+/).filter(word => word.length > 0).length;

        // Calculate the reading time in minutes
        const readingTime = wordCount / wpm;

        return readingTime.toFixed(0);
    }

    return (
        <Grid container spacing={3}>
            {posts.map((post, index) => (
                <Grid item xs={12} sm={4} key={post.slug}>
                    <Card raised sx={{ display: 'flex', height: '100%' }}>
                        <CardMedia
                            component="img"
                            sx={{ width: 160, objectFit: 'cover' }}
                            image={post.image}
                            alt={post.title}
                        />
                        <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                            <CardContent>
                                <Typography gutterBottom variant="h6" component="div" style={styles.lineClamp}>
                                    <Link to={`/post/${post.slug}`} style={{ textDecoration: 'none', color: "black", fontWeight: "bold" }}>{post.title}</Link>
                                </Typography>
                                <Typography variant="body2" color="text.secondary" style={styles.lineClamp}>
                                    {post.summary}
                                </Typography>
                            </CardContent>
                            <Box sx={{ mt: 'auto', p: 2, display: 'flex', alignItems: 'center' }}>
                                <AccessTime fontSize="small" />
                                <Typography variant="caption" sx={{ ml: 0.5 }}>
                                    {calculateReadingTime(post.body)} minute read
                                </Typography>
                            </Box>
                        </Box>
                    </Card>
                </Grid>
            ))}
        </Grid>
    );
};

export default newPosts;
