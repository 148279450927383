import React, { useEffect, useState } from 'react';
import {Box, Grid, Typography, CardMedia, CardContent, Card} from '@mui/material';
import PostsService from '../api/PostsService';
import {AccessTime} from "@mui/icons-material";
import {NavLink} from "react-router-dom";

const RelatedPosts = ({ category }) => {
    const [relatedPosts, setRelatedPosts] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(true);
        PostsService.fetchPosts(1, 10, window.location.hostname)
            .then(response => {
                setRelatedPosts(response.data.data.slice(0, 10));
                setIsLoading(false);
            })
            .catch(error => {
                console.error("Failed to fetch related posts:", error);
                setIsLoading(false);
            });
    }, [category]);

    if (isLoading) {
        return <Box textAlign="center"><Typography>Loading...</Typography></Box>;
    }

    if (!relatedPosts || relatedPosts.length === 0) {
        return <Typography variant="h6">No related posts available</Typography>;
    }

    const styles = {
        lineClamp: {
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            WebkitLineClamp: 2,
            lineClamp: 2,
        },
    };

    const calculateReadingTime = (body, wpm = 200) => {
        // Parse the body JSON string into an object
        const content = JSON.parse(body);

        // Extract the text from the paragraphs
        let text = "";
        content.forEach(section => {
            section.paragraphs.forEach(paragraph => {
                text += paragraph.text + " ";
            });
        });

        // Count the number of words
        const wordCount = text.split(/\s+/).filter(word => word.length > 0).length;

        // Calculate the reading time in minutes
        const readingTime = wordCount / wpm;

        return readingTime.toFixed(0);
    }


    return (
        <Box sx={{ mt: 4 }}>
            <Typography variant="h6" gutterBottom>Related Posts</Typography>
            <Grid container spacing={2}>
                {relatedPosts.map((post) => (
                    <Grid item xs={12} sm={12} key={post.slug}>
                        <Card raised sx={{ display: 'flex', height: '100%' }}>
                            <CardMedia
                                component="img"
                                sx={{ width: 160, objectFit: 'cover' }}
                                image={post.image}
                                alt={post.title}
                            />
                            <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                                <CardContent>
                                    <Typography gutterBottom variant="h6" component="div" style={styles.lineClamp}>
                                        <NavLink to={`/post/${post.slug}`} style={{ textDecoration: 'none', color: "black", fontWeight: "bold" }}>
                                            {post.title}
                                        </NavLink>
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" style={styles.lineClamp}>
                                        {post.summary}
                                    </Typography>
                                </CardContent>
                                <Box sx={{ mt: 'auto', p: 2, display: 'flex', alignItems: 'center' }}>
                                    <AccessTime fontSize="small" />
                                    <Typography variant="caption" sx={{ ml: 0.5 }}>
                                        {calculateReadingTime(post.body)} minute read
                                    </Typography>
                                </Box>
                            </Box>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default RelatedPosts;
