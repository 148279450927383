import { useEffect } from 'react';

const MgidWidget = () => {
    useEffect(() => {
        const script = document.createElement("script");
        script.innerHTML = `(function(w,q){w[q]=w[q]||[];w[q].push(["_mgc.load"])})(window,"_mgq");`;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return <div data-type="_mgwidget" data-widget-id="1646876"></div>;
};

export default MgidWidget;