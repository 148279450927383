import React from 'react';
import { Helmet } from 'react-helmet';

const MgidHeadTags = ({ hostname }) => {
    const getHeadTags = () => {
        switch (hostname) {
            case '4hoome.com':
                return (
                    <Helmet>
                        <script src="https://jsc.mgid.com/site/972553.js" async>
                    </script>

            </Helmet>
                );
            default:
                return null;
        }
    };

    return getHeadTags();
};

export default MgidHeadTags;