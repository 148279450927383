import React, { useEffect, useState } from 'react';
import {
    Box,
    Container,
    Grid,
    Typography,
    Link,
    Breadcrumbs,
    CircularProgress,
    Button,
} from '@mui/material';
import PostsService from '../api/PostsService';
import { useParams } from 'react-router-dom';
import RelatedPosts from './RelatedPosts';
import { Helmet } from 'react-helmet';
import TaboolaAd from './TaboolaAd';
import OutbrainHeadTags from './OutbrainHeadTags';
import { useMediaQuery } from 'react-responsive';
import MgidHeadTags from './MgidHeadTags';
import MgidWidget from './MgidWidget';

const Post = () => {
    const { slug } = useParams();
    const [post, setPost] = useState(null);
    const [tableOfContents, setTableOfContents] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isExpanded, setIsExpanded] = useState(false);
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
    const pageUrl = window.location.href;
    const hostname = window.location.hostname;

    useEffect(() => {
        if (slug) {
            setIsLoading(true);
            PostsService.viewPost(slug)
                .then((response) => {
                    setPost(response.data);
                    const headings = JSON.parse(response.data.body)
                        .filter((item) => item.type === 'headline')
                        .map((item, index) => ({
                            id: `heading-${index}`,
                            text: item.text,
                        }));
                    setTableOfContents(headings);
                    setIsLoading(false);
                })
                .catch((error) => {
                    console.error('Failed to fetch post:', error);
                });
        }
    }, [slug]);

    const handleExpandClick = () => {
        setIsExpanded(!isExpanded);
    };

    const renderContent = (content) => {
        const contentArray = JSON.parse(content);
        return contentArray.map((item, index) => {
            if (item.type === 'headline') {
                return (
                    <React.Fragment key={index}>
                        {(isExpanded || index === 0) && (
                            <Typography
                                id={`heading-${index}`}
                                variant="h5"
                                component="h2"
                                gutterBottom
                            >
                                {item.text}
                            </Typography>
                        )}
                        {item.paragraphs.map(
                            (para, paraIndex) =>
                                ((index === 0 && paraIndex === 0) || isExpanded) && (
                                    <Typography
                                        key={`paragraph-${index}-${paraIndex}`}
                                        paragraph
                                    >
                                        {para.text}
                                    </Typography>
                                )
                        )}
                    </React.Fragment>
                );
            } else {
                return null; // For any content type not handled
            }
        });
    };

    const gptSlotHostnames = [
        'insurepioneer.com',
        'smartcreditline.com',
        'procasacraft.com',
        'smarttechtalks.com',
        'nomadicnotes.net',
        'moneymasterymatters.com',
        'culinarycreationscorner.com',
        'activewellnessways.com',
    ];

    const renderAdComponent = () => {
        if (hostname === '4hoome.com') {
            return <MgidWidget />;
        } else if (hostname === 'homehelptoday.net') {
            return (
                <div>
                    <div
                        className="OUTBRAIN"
                        data-src={pageUrl}
                        data-widget-id="AR_1"
                    ></div>
                </div>
            );
        } else if (gptSlotHostnames.includes(hostname)) {
            return <div className="gptslot" data-adunitid="1"></div>;
        } else if (hostname === 'ainewspaper.net') {
            return (
                <TaboolaAd
                    mode="thumbnails-a-3x2"
                    targetId="taboola-below-article-thumbnails"
                    placement="Below Article Thumbnails"
                />
            );
        } else {
            return (
                <TaboolaAd
                    mode={isMobile ? 'thumbnails-a' : 'thumbs-feed-01'}
                    placement={
                        isMobile
                            ? 'taboola-mobile-below-article-thumbnails'
                            : 'Desktop Below Article Feed'
                    }
                    targetId={
                        isMobile
                            ? 'Mobile below article thumbnails'
                            : 'taboola-desktop-below-article-feed'
                    }
                />
            );
        }
    };

    if (isLoading) {
        return (
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="100vh"
            >
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Container maxWidth="xl">
            <Helmet>
                <title>{`${hostname} - ${post ? post.title : ''}`}</title>
                <meta name="description" content={post ? post.summary : ''} />
            </Helmet>
            <OutbrainHeadTags hostname={hostname} />
            <MgidHeadTags hostname={hostname} />
            <Grid container spacing={3}>
                <Grid item xs={12} md={7}>
                    {post && (
                        <>
                            <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 1 }}>
                                <Link underline="hover" color="inherit" href="/">
                                    Home
                                </Link>
                                <Link underline="hover" color="inherit" href="/#">
                                    {post.category}
                                </Link>
                                <Typography color="text.primary">{post.title}</Typography>
                            </Breadcrumbs>
                            <Typography variant="h3" component="h1" gutterBottom>
                                {post.title}
                            </Typography>
                            {post.image && (
                                <Box textAlign="center" my={2}>
                                    <img
                                        src={post.image}
                                        alt={post.title}
                                        style={{ maxWidth: '100%' }}
                                    />
                                </Box>
                            )}
                            {renderContent(post.body)}
                            {!isExpanded && (
                                <Box textAlign="center" my={2}>
                                    <Button variant="contained" onClick={handleExpandClick}>
                                        Read More
                                    </Button>
                                </Box>
                            )}
                            <Box my={4} textAlign="center">
                                {renderAdComponent()}
                            </Box>
                        </>
                    )}
                </Grid>
                <Grid item xs={12} md={5}>
                    <Box
                        position="sticky"
                        top={20}
                        sx={{
                            border: '1px solid #ccc',
                            padding: '10px',
                        }}
                    >
                        <Typography variant="h6">Contents</Typography>
                        <ul>
                            {tableOfContents.map((item, index) => (
                                <li key={index}>
                                    <Link href={`#${item.id}`} color="inherit">
                                        {item.text}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                        <RelatedPosts category={post.category} />
                    </Box>
                </Grid>
            </Grid>
        </Container>
    );
};

export default Post;