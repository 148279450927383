import React, { useEffect } from 'react';

const TaboolaAd = ({ mode, placement, targetId }) => {
    useEffect(() => {
        // Ensure the Taboola window object is available
        window._taboola = window._taboola || [];
        window._taboola.push({
            mode: mode,
            container: targetId,
            placement: placement,
            target_type: 'mix'
        });

        // Load the Taboola widget
        window._taboola.push({flush: true});
    }, [mode, placement, targetId]);

    return <div id={targetId}></div>;
};

export default TaboolaAd;
